var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',{staticClass:"treeControl",attrs:{"id":[_vm.id]}},[_c('div',{staticClass:"outer-node"},[_c('div',{class:[
          'inner-node',
          _vm.selected
            ? 'node-selected'
            : _vm.highlight
            ? _vm.isClinicalMatch
              ? 'highlight'
              : 'highlight-left'
            : '',
          _vm.treeRoot ? 'root-node' : '' ],on:{"click":_vm.toggleSelection}},[(_vm.hasChildren && _vm.canToggle)?_c('div',{class:[
            'toggle-icon',
            _vm.inMatchPath ? 'toggle-circle' : 'toggle-circle-notmatch',
            _vm.selected ? 'toggle-selected' : '',
            _vm.showChildren ? 'toggle-down' : 'toggle-right' ],on:{"click":function($event){$event.stopPropagation();return _vm.toggleChildren.apply(null, arguments)}}}):_vm._e(),_c('div',{staticClass:"node-text"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatIndicationText(_vm.label, _vm.calculators))+" ")]),(_vm.citations.length)?_c('div',{staticClass:"node-citations",on:{"click":function($event){$event.stopPropagation();return _vm.toggleReference('citations')}}},[_vm._v(" [Refs] ")]):_vm._e(),(_vm.annotations.length)?_c('div',{staticClass:"node-annotations",on:{"click":function($event){return _vm.toggleReference('annotations', $event)}}},[_c('font-awesome-icon',{attrs:{"icon":"comment-alt"}})],1):_vm._e()]),(_vm.duplicate)?_c('div',{staticClass:"node-duplicate"}):_vm._e(),(_vm.isClinicalMatch && _vm.hasMatchingClinicalInput)?_c('div',{class:['indication-clinical-input', _vm.selected ? 'selected' : '']},[_vm._l((_vm.filteredClinicalInput),function(input,i){return _c('div',{key:input.clinicalInputKey,attrs:{"itemlabel":input.name}},[(i < _vm.clinicalInputAmount)?_c('div',[_c('div',[_vm._v(_vm._s(input.name))]),_c('div',[_vm._v(_vm._s(_vm.convertIsoToDateTime(input.dateTime)))]),(_vm.filteredClinicalInput.some(function (i) { return i.value; }))?_c('div',[(input.value)?_c('div',[_vm._v(" "+_vm._s(input.value)+" "+_vm._s(input.unit)+" ")]):_vm._e()]):_vm._e()]):_vm._e()])}),_vm._l((_vm.calculatorMatches),function(calc,i){return _c('div',{key:calc.id},[(i + _vm.filteredClinicalInput.length < _vm.clinicalInputAmount)?_c('div',[_c('div',[_c('b',[_vm._v("Calculator: ")]),_vm._v(_vm._s(calc.text)+" "),_c('b',[_vm._v("Score: ")]),_vm._v(_vm._s(calc.score))])]):_vm._e()])})],2):_vm._e(),(
            !_vm.treeRoot &&
            (_vm.filteredClinicalInput.length || _vm.indicationNoteEditor)
          )?_c('div',{class:['indication-clinical-input', 'colorInvert']},[(_vm.indicationNoteEditor)?_c('div',{staticClass:"indication-editor-container"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.treeNodeNote),expression:"treeNodeNote",modifiers:{"trim":true}}],ref:"textarea",staticClass:"indication-editor",class:{ borderoverlimit: _vm.overLimit },attrs:{"placeholder":"Type note text here...","rows":"7","maxlength":"1000"},domProps:{"value":(_vm.treeNodeNote)},on:{"click":function($event){$event.stopPropagation();return _vm.setFocus.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.treeNodeNote=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('div',{staticClass:"editor-helper-text",class:{ overlimit: _vm.overLimit }},[_c('small',[_vm._v("max. characters 1000")]),_c('small',{staticClass:"note-length"},[_vm._v(_vm._s(_vm.treeNodeNote.length))])])]):_vm._e()]):_vm._e(),(_vm.showIndicationNoteIcon && !_vm.treeRoot)?_c('div',{staticClass:"note-icon"},[_c('img',{attrs:{"src":require(("@assets/images/icons/" + (_vm.noteIconSource || 'addNote.png'))),"alt":"Note Icon Alternate Text"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleIndicationNote.apply(null, arguments)}}})]):_vm._e()])]),(_vm.showChildren)?_c('ul',_vm._l((_vm.nodes),function(node){return _c('TreeNode',{key:node.PathId,attrs:{"id":node.PathId,"path":node.PathId,"item-path":node.itemPath,"uid":node.uid,"definition-id":node.definitionId,"nodes":node.ContentItems,"current-node":node,"label":node.text,"match-paths":_vm.matchPaths,"highlight":node.conditionsMet,"node-level":node.nodeLevel,"sort-order":node.sortOrder,"is-codified":node.isCodified,"depth":_vm.depth + 1,"list-logic":node.ListLogic,"citations":node.CitationLinks,"annotations":node.AnnotationLinks,"duplicate":node.indicationsWithConditionsMet
            ? node.indicationsWithConditionsMet.dupIndicationMatch
            : false,"calculators":node.CalculatorTypes,"is-in-definition":node.definitionId || _vm.isInDefinition,"note-text":_vm.getIndicationNote(node.uid),"expanded":_vm.hasSelectedChildren},on:{"bubbleListLogic":_vm.manageListLogic}})}),1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }