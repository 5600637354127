<script>
import { MAX_ROWS } from '@utils/constants'
import DataUtils from '@utils/clinical-data-utils'
import { formatDateMixin } from '@mixins/format-date-mixin'
import { mapActions, mapGetters } from 'vuex'
import ClinicalInputCard from '@components/clinical-input-card.vue'
import AttachmentPanel from '@components/attachments-panel.vue'
import StringUtils from '@utils/string-utils'
import { DateTime } from 'luxon'
import ClinicalNotePdf from '@components/clinical-note-pdf.vue'

export default {
  components: { ClinicalInputCard, AttachmentPanel, ClinicalNotePdf },
  mixins: [formatDateMixin],
  props: {
    printMode: {
      type: Boolean,
      default: false,
    },
    savedClinicalNoteSort: {
      type: Array,
      default: () => ['date', 'desc'],
    },
  },
  data() {
    return {
      refreshKey: 0,
      problems: {
        data: (categorizedClinicalInput) =>
          this.makeTableData(categorizedClinicalInput?.problemCodes)
            ?.map((item) => ({
              id: item.clinicalInputKey,
              name: item.Name,
              date: item.DateTime,
              matchingIndicationPaths: item.matchingIndicationPaths,
              resourceId: item.clinicalInputId,
              includeInAuthRequest: !this.isExcluded(item.clinicalInputId),
              resourceType: 'problems',
            }))
            ?.filter(
              (item, pos, self) =>
                self.findIndex((v) => v.resourceId === item.resourceId) ===
                  pos &&
                self.findIndex(
                  (v) => v.name === item.name && v.date === item.date
                ) === pos
            ),
      },
      procedures: {
        data: (categorizedClinicalInput) =>
          this.makeTableData(categorizedClinicalInput?.procedureCodes)
            ?.map((item) => ({
              id: item.clinicalInputKey,
              name: item.Name,
              date: item.DateTime,
              status: item.Status,
              matchingIndicationPaths: item.matchingIndicationPaths,
              resourceId: item.clinicalInputId,
              includeInAuthRequest: !this.isExcluded(item.clinicalInputId),
              resourceType: 'procedures',
            }))
            ?.filter(
              (item, pos, self) =>
                self.findIndex((v) => v.resourceId === item.resourceId) ===
                  pos &&
                self.findIndex(
                  (v) =>
                    v.name === item.name &&
                    v.date === item.date &&
                    v.status === item.status
                ) === pos
            ),
      },
      vitals: {
        data: (categorizedClinicalInput) =>
          DataUtils.take(
            this.sortByDateDescending(
              DataUtils.values(categorizedClinicalInput?.vitalSigns)?.filter(
                (item) =>
                  !StringUtils.isNullOrWhitespace(item.Name) &&
                  !StringUtils.isNullOrWhitespace(item.DateTime) &&
                  formatDateMixin.methods.isValidIsoDate(item.DateTime) &&
                  !StringUtils.isNullOrWhitespace(item.Value?.toString())
              )
            ),
            MAX_ROWS
          )
            ?.map((item) => ({
              id: item.clinicalInputKey,
              name: item.Name,
              date: item.DateTime,
              value: `${item.Value} ${item.Units ?? ''}`,
              matchingIndicationPaths: item.matchingIndicationPaths,
              resourceId: item.clinicalInputId,
              includeInAuthRequest: !this.isExcluded(item.clinicalInputId),
              resourceType: 'vitals',
            }))
            ?.filter(
              (item, pos, self) =>
                self.findIndex((v) => v.resourceId === item.resourceId) ===
                  pos &&
                self.findIndex(
                  (v) =>
                    v.name === item.name &&
                    v.date === item.date &&
                    v.value === item.value
                ) === pos
            ),
      },
      labs: {
        data: (categorizedClinicalInput) =>
          DataUtils.take(
            this.sortByDateDescending(
              DataUtils.values(categorizedClinicalInput?.labdata)?.filter(
                function (item) {
                  return (
                    !StringUtils.isNullOrWhitespace(item.Name) &&
                    !StringUtils.isNullOrWhitespace(item.DateTime) &&
                    formatDateMixin.methods.isValidIsoDate(item.DateTime) &&
                    !StringUtils.isNullOrWhitespace(item.Value?.toString())
                  )
                }
              )
            ),
            MAX_ROWS
          )
            ?.map((item) => ({
              id: item.clinicalInputKey,
              name: item.Name,
              date: item.DateTime,
              value: `${item.Value} ${item.Units ?? ''}`,
              status: item.Status,
              interpretation: item.Interpretation,
              matchingIndicationPaths: item.matchingIndicationPaths,
              resourceId: item.clinicalInputId,
              includeInAuthRequest: !this.isExcluded(item.clinicalInputId),
              resourceType: 'labs',
            }))
            ?.filter(
              (item, pos, self) =>
                self.findIndex((v) => v.resourceId === item.resourceId) ===
                  pos &&
                self.findIndex(
                  (v) =>
                    v.name === item.name &&
                    v.date === item.date &&
                    v.value === item.value &&
                    v.status === item.status &&
                    v.interpretation === item.interpretation
                ) === pos
            ),
      },
      medications: {
        data: (categorizedClinicalInput) => {
          const medications =
            categorizedClinicalInput?.rawMedicationData?.medicationRequest
              ?.map((item) => ({
                id: item.id,
                name:
                  item?.medicationReference?.display ??
                  DataUtils.extractCodeDisplayFromConcept(
                    item?.medicationCodeableConcept
                  ),
                date: item.authoredOn,
                epochDateTime: DateTime.fromISO(item.authoredOn).toMillis(),
                status: item.status,
                dose: DataUtils.makeDoseDisplay(
                  item?.dosageInstruction?.[0]?.doseAndRate?.[0]?.doseQuantity
                ),
                route: DataUtils.extractCodeDisplayFromConcept(
                  item?.dosageInstruction?.[0]?.route
                ),
                frequency: DataUtils.extractCodeDisplay(
                  item?.dosageInstruction?.[0]?.timing
                ),
                instructions: item?.dosageInstruction?.[0]?.text,
                administrations: this.matchingMedicationAdministrations(
                  categorizedClinicalInput?.medicationAdministrations,
                  item
                ),
                matchingIndicationPaths: this.medicationMatchingIndicationPaths(
                  categorizedClinicalInput?.medicationAdministrations,
                  item
                ),
                showInstructions: false,
                showAdministrations: false,
                includeInAuthRequest: !this.isExcluded(item.id),
                resourceType: 'medications',
              }))
              ?.filter(
                (item, pos, self) =>
                  item.name?.length > 0 &&
                  !StringUtils.isNullOrWhitespace(item.date) &&
                  formatDateMixin.methods.isValidIsoDate(item.date) &&
                  self.findIndex(
                    (v) =>
                      v.name === item.name &&
                      v.date === item.date &&
                      v.status === item.status &&
                      v.dose === item.dose &&
                      v.route === item.route &&
                      v.frequency === item.frequency &&
                      v.instructions === item.instructions
                  ) === pos
              )

          return medications
            ?.sort((a, b) => b.epochDateTime - a.epochDateTime)
            ?.slice(0, MAX_ROWS)
        },
      },
      diagnosticReports: {
        data: (categorizedClinicalInput) =>
          DataUtils.take(
            this.sortByDateDescending(
              categorizedClinicalInput?.diagnosticReports?.filter(
                (item) =>
                  !StringUtils.isNullOrWhitespace(
                    DataUtils.extractCodeDisplay(item)
                  )
              ),
              (item) => item.effectiveDateTime
            ),
            MAX_ROWS
          )?.map((item) => ({
            id: item.id,
            name: DataUtils.extractCodeDisplay(item),
            date: item.effectiveDateTime,
            status: item.status,
            results: DataUtils.extractDiagnosticReportExtendedResults(item),
            includeInAuthRequest: !this.isExcluded(item.clinicalInputId),
            resourceType: 'diagnosticReports',
          })),
      },
      clinicalNotes: {
        data: (categorizedClinicalInput) =>
          DataUtils.take(
            this.sortByDateDescending(
              DataUtils.values(categorizedClinicalInput?.clinicalNotes)?.filter(
                (item) =>
                  !StringUtils.isNullOrWhitespace(item.date) &&
                  formatDateMixin.methods.isValidIsoDate(item.date) &&
                  (!StringUtils.isNullOrWhitespace(
                    item.content[0].attachment.url
                  ) ||
                    !StringUtils.isNullOrWhitespace(
                      item.content[0].attachment.data
                    ))
              )
            ),
            MAX_ROWS
          )?.map((item) => ({
            id: item.id,
            name: item.description || item.type?.text,
            date: item.date,
            noteAuthor: !StringUtils.isNullOrWhitespace(item.author[0].display)
              ? item.author[0].display
              : '',
            contentType: item.content[0].attachment.contentType,
            attachmentUrl: item.content[0].attachment.url,
            binaryData: item.content[0].attachment.data,
            showClinicalNote: false,
            includeInAuthRequest: !this.isExcluded(item.clinicalInputId),
            resourceType: 'clinicalNotes',
            isDownloading: false,
          })),
      },
    }
  },
  computed: {
    ...mapGetters('guidelineData', [
      'clinicalInputs',
      'categorizedClinicalInput',
      'excludeFromAuthRequest',
      'clinicalNotesBinary',
      'showClinicalNote',
    ]),
    ...mapGetters('documentGuideline', ['documentedIndications']),
    ...mapGetters('clientConfig', ['clientConfig']),
    ...mapGetters('fhirClinicalNotesBinary', ['sortOrder']),
    diagnosticReportsDetailRows() {
      return this.diagnosticReports
        .data(this.categorizedClinicalInput)
        ?.map((x) => x.id)
    },
    displayClinicalDataCheckboxes() {
      return this.clientConfig.displayClinicalDataCheckboxes
    },
    displayClinicalInputCards() {
      const noData =
        this.problems.data(this.categorizedClinicalInput)?.length === 0 &&
        this.procedures.data(this.categorizedClinicalInput)?.length === 0 &&
        this.vitals.data(this.categorizedClinicalInput)?.length === 0 &&
        this.labs.data(this.categorizedClinicalInput)?.length === 0 &&
        this.medications.data(this.categorizedClinicalInput)?.length === 0 &&
        this.diagnosticReports.data(this.categorizedClinicalInput)?.length ===
          0 &&
        this.clinicalNotes.data(this.categorizedClinicalInput)?.length === 0

      return !noData
    },
    clinicalNotesDetailRows() {
      const rows = this.clinicalNotes
        .data(this.categorizedClinicalInput)
        ?.map((x) => this.showClinicalNote(x.id))

      return rows
    },
  },
  methods: {
    isExcluded(id) {
      const exclude = this.excludeFromAuthRequest.find((x) =>
        x.clinicalInputId.includes(id)
      )

      return !!exclude
    },
    sortIncludingEmptyValues(fieldNameOrExtractor) {
      const extractor =
        fieldNameOrExtractor instanceof Function
          ? fieldNameOrExtractor
          : (item) => item?.[fieldNameOrExtractor]

      return (a, b, isAsc) => {
        const aValue = extractor(a)?.toString() ?? ''
        const bValue = extractor(b)?.toString() ?? ''

        if (isAsc) {
          return aValue.localeCompare(bValue, 'en', { sensitivity: 'base' })
        }

        return bValue.localeCompare(aValue, 'en', { sensitivity: 'base' })
      }
    },
    sortDate(dateExtractor) {
      return this.sortIncludingEmptyValues((item) =>
        this.isValidIsoDate(dateExtractor(item)) ? dateExtractor(item) : null
      )
    },
    sortByDateDescending(array, dateExtractor = (item) => item.DateTime) {
      return array?.sort((a, b) => this.sortDate(dateExtractor)(a, b, false))
    },
    makeTableData(object) {
      return DataUtils.take(
        this.sortByDateDescending(
          DataUtils.removeIncomplete(DataUtils.values(object))
        ),
        MAX_ROWS
      )
    },
    medicationHasAdditionalData(medicationItem) {
      return (
        medicationItem.dose ||
        medicationItem.route ||
        medicationItem.frequency ||
        medicationItem.instructions
      )
    },
    matchingMedicationAdministrations(
      medicationAdministrations,
      medicationRequest
    ) {
      return medicationAdministrations != null
        ? Object.values(medicationAdministrations)
            ?.map((ma) => ({
              ...ma,
              epochDateTime: DateTime.fromISO(ma.dateTime).toMillis(),
            }))
            ?.filter(
              (ma) =>
                ma.orderReference?.endsWith(medicationRequest.id) === true ||
                ma.codings?.some((maCode) =>
                  medicationRequest?.medicationCodeableConcept?.coding.some(
                    (mrCode) => maCode.code === mrCode.code
                  )
                )
            )
            ?.sort((a, b) => b.epochDateTime - a.epochDateTime)
        : []
    },
    medicationMatchingIndicationPaths(
      medicationAdministrations,
      medicationRequest
    ) {
      return this.matchingMedicationAdministrations(
        medicationAdministrations,
        medicationRequest
      )
        .flatMap((ma) => ma.matchingIndicationPaths)
        .filter((ma) => ma != null)
    },
    toggleMedicationDetails(props) {
      if (!props.row.detailVisible) {
        props.toggleDetails(props.row)
      }
      props.row.showInstructions = !props.row.showInstructions
      if (
        props.row.detailVisible &&
        !props.row.showInstructions &&
        !props.row.showAdministrations
      ) {
        props.toggleDetails(props.row)
      }
    },
    toggleMedicationAdministrations(props) {
      if (!props.row.detailVisible) {
        props.toggleDetails(props.row)
      }
      props.row.showAdministrations = !props.row.showAdministrations
      if (
        props.row.detailVisible &&
        !props.row.showInstructions &&
        !props.row.showAdministrations
      ) {
        props.toggleDetails(props.row)
      }
    },
    binaryData(row) {
      const data = this.clinicalNotesBinary(row.id)
      if (row.contentType === 'text/html') {
        return StringUtils.sanitizeHtmlWithAllowedHtml(data)
      }

      return data
    },
    getBinaryHtml(contentData) {
      return StringUtils.sanitizeHtmlWithAllowedHtml(contentData)
    },
    getBinary(props) {
      this.retrieveClinicalNotesBinary({
        sessionId: this.$route.query.sessionId,
        contentPath: props.row.attachmentUrl,
        index: props.row.id,
      })
        .then((response) => {
          this.setClinicalNotesBinaryData({
            id: props.row.id,
            data: response.data,
          })

          if (response) {
            this.refreshKey += 1
            props.row.isDownloading = false
            this.toggleDetails(props)
          } else {
            // eslint-disable-next-line no-console
            console.log(
              `Error getting Clinical Note Binary. URL: ${props.row.attachmentUrl}`
            )
          }
        })
        .catch((error) => {
          return error
        })
    },
    toggleClinicalNotes(props) {
      if (props.row.isDownloading) {
        return
      }
      if (!this.clinicalNotesBinary(props.row.id)) {
        props.row.isDownloading = true
        this.getBinary(props)
      } else {
        this.toggleDetails(props)
      }
    },
    toggleDetails(props) {
      const value = !!this.showClinicalNote(props.row.id)
      this.setShowClinicalNote({ id: props.row.id, value: !value })
      props.toggleDetails(props.row)
    },
    toggleIncludeInAuthRequestBulk(e, clinicalInputId, lst) {
      this.toggleIncludeInAuthRequest(e, clinicalInputId)

      for (const el of lst) {
        this.toggleIncludeInAuthRequest(e, el.clinicalInputKey)
      }
    },
    toggleIncludeInAuthRequest(e, clinicalInputId) {
      // get name, date, value from element
      const excludedData = {}
      const tds = Array.from(e.target.parentElement.parentElement.children)

      excludedData.clinicalInputId = clinicalInputId
      tds.forEach((td) => {
        if (td.attributes['data-label']) {
          const label = td.attributes['data-label'].value // Observation
          const text = td.innerText

          if (label !== 'Include in Auth Request') {
            excludedData[label] = text
          }
        }
      })

      // get categorizedClinicalInput

      const segments = clinicalInputId?.split('_') || []
      const resourceType =
        segments.length > 0 ? segments[segments.length - 1] : null

      let resource

      switch (resourceType) {
        case 'Condition':
          resource = this.problems
            .data(this.categorizedClinicalInput)
            .find((item) => item.id === clinicalInputId)
          break
        case 'Procedure':
          resource = this.procedures
            .data(this.categorizedClinicalInput)
            .find((item) => item.id === clinicalInputId)
          break
        case 'labresults':
          resource = this.labs
            .data(this.categorizedClinicalInput)
            .find((item) => item.id === clinicalInputId)
          break
        case 'vitalSigns':
          resource = this.vitals
            .data(this.categorizedClinicalInput)
            .find((item) => item.id === clinicalInputId)
          break
        default:
          resource = this.medications
            .data(this.categorizedClinicalInput)
            .find((item) => item.id === clinicalInputId)
          if (!resource) {
            resource = this.diagnosticReports
              .data(this.categorizedClinicalInput)
              .find((item) => item.id === clinicalInputId)
          }
          if (!resource) {
            resource = this.clinicalNotes
              .data(this.categorizedClinicalInput)
              .find((item) => item.id === clinicalInputId)
          }
          break
      }

      if (resource && resource.matchingIndicationPaths) {
        excludedData.pathId = resource.matchingIndicationPaths
      }

      let data = [...this.excludeFromAuthRequest]
      if (!e.target.checked) {
        data.push(excludedData)
      } else {
        data = data.filter((item) => item.clinicalInputId !== clinicalInputId)
      }
      this.setExcludeFromAuthRequest(data)
      this.updatePreview(this.clinicalInputs)
    },
    ...mapActions('fhirClinicalNotesBinary', ['retrieveClinicalNotesBinary']),
    ...mapActions('guidelineData', [
      'setClinicalNotesBinaryData',
      'setExcludeFromAuthRequest',
      'setShowClinicalNote',
    ]),
    ...mapActions('documentGuideline', ['updatePreview']),
  },
}
</script>

<template>
  <div class="bounded-scroll clinical-input-container">
    <div v-if="displayClinicalInputCards">
      <ClinicalInputCard
        title="Problems"
        resource-name="problem"
        :print-mode="printMode"
        :icon-path="require('@assets/images/icons/problem-list.png')"
        :table-data="problems.data(categorizedClinicalInput)"
        search-column="name"
        :default-sort="['date', 'desc']"
      >
        <b-table-column
          v-slot="props"
          field="name"
          label="Problem"
          header-class="middleAlign"
          sortable
          >{{ props.row.name }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          field="date"
          label="Date"
          header-class="middleAlign"
          sortable
          :custom-sort="sortDate((item) => item.date)"
        >
          <span :data-date-time="props.row.date">{{
            convertIsoToDate(props.row.date)
          }}</span>
        </b-table-column>

        <b-table-column
          v-if="displayClinicalDataCheckboxes"
          field="includeInAuthRequest"
          label="Include in Auth Request"
          width="150px"
          centered
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <b-icon
              icon="circle-info"
              class="includeInAuthRequest"
              title=" Please use the checkboxes to unselect any clinical data you do not wish to be included in the authorization request summary"
            >
            </b-icon>
          </template>
          <template v-slot="props">
            <input
              v-model="props.row.includeInAuthRequest"
              type="checkbox"
              @change="toggleIncludeInAuthRequest($event, props.row.id)"
            />
          </template>
        </b-table-column>
      </ClinicalInputCard>

      <ClinicalInputCard
        :key="refreshKey"
        title="Procedures"
        resource-name="procedure"
        :print-mode="printMode"
        :icon-path="require('@assets/images/icons/procedures-list.png')"
        :table-data="procedures.data(categorizedClinicalInput)"
        search-column="name"
        :default-sort="['date', 'desc']"
      >
        <b-table-column
          v-slot="props"
          field="name"
          label="Procedure"
          header-class="middleAlign"
          sortable
          >{{ props.row.name }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          field="date"
          label="Date"
          header-class="middleAlign"
          sortable
          :custom-sort="sortDate((item) => item.date)"
        >
          <span :data-date-time="props.row.date">{{
            convertIsoToDate(props.row.date)
          }}</span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="status"
          label="Status"
          header-class="middleAlign"
          sortable
          :custom-sort="sortIncludingEmptyValues('status')"
          >{{ props.row.status }}</b-table-column
        >
        <b-table-column
          v-if="displayClinicalDataCheckboxes"
          field="includeInAuthRequest"
          label="Include in Auth Request"
          width="150px"
          centered
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <b-icon
              title="Please use the checkboxes to unselect any clinical data you do not wish to be included in the authorization request summary"
              icon="circle-info"
              class="includeInAuthRequest"
            />
          </template>
          <template v-slot="props">
            <input
              v-model="props.row.includeInAuthRequest"
              type="checkbox"
              @change="toggleIncludeInAuthRequest($event, props.row.id)"
            />
          </template>
        </b-table-column>
      </ClinicalInputCard>

      <ClinicalInputCard
        title="Recent Vitals"
        resource-name="observation"
        :print-mode="printMode"
        :icon-path="require('@assets/images/icons/recent-vitals.png')"
        :table-data="vitals.data(categorizedClinicalInput)"
        search-column="name"
        :default-sort="['date', 'desc']"
      >
        <b-table-column
          v-slot="props"
          field="name"
          label="Observation"
          header-class="middleAlign"
          sortable
          >{{ props.row.name }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          field="date"
          label="Date"
          header-class="middleAlign"
          sortable
          :custom-sort="sortDate((item) => item.date)"
        >
          <span :data-date-time="props.row.date">{{
            convertIsoToDateTime(props.row.date)
          }}</span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="value"
          label="Value"
          header-class="middleAlign"
          sortable
          >{{ props.row.value }}</b-table-column
        >
        <b-table-column
          v-if="displayClinicalDataCheckboxes"
          field="includeInAuthRequest"
          label="Include in Auth Request"
          width="150px"
          centered
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <b-icon
              title="Please use the checkboxes to unselect any clinical data you do not wish to be included in the authorization request summary"
              icon="circle-info"
              class="includeInAuthRequest"
            />
          </template>
          <template v-slot="props">
            <input
              v-model="props.row.includeInAuthRequest"
              type="checkbox"
              @change="toggleIncludeInAuthRequest($event, props.row.id)"
            />
          </template>
        </b-table-column>
      </ClinicalInputCard>

      <ClinicalInputCard
        title="Lab Results"
        resource-name="observation"
        :print-mode="printMode"
        :icon-path="require('@assets/images/icons/lab-results.png')"
        :table-data="labs.data(categorizedClinicalInput)"
        search-column="name"
        :default-sort="['date', 'desc']"
      >
        <b-table-column
          v-slot="props"
          field="name"
          label="Observation"
          header-class="middleAlign"
          sortable
          >{{ props.row.name }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          field="date"
          label="Date"
          header-class="middleAlign"
          sortable
          :custom-sort="sortDate((item) => item.date)"
        >
          <span :data-date-time="props.row.date">{{
            convertIsoToDateTime(props.row.date)
          }}</span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="value"
          label="Value"
          header-class="middleAlign"
          sortable
          >{{ props.row.value }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          field="status"
          label="Status"
          header-class="middleAlign"
          sortable
          :custom-sort="sortIncludingEmptyValues('status')"
          >{{ props.row.status }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          field="interpretation"
          label="Interpretation"
          header-class="middleAlign"
          sortable
          :custom-sort="sortIncludingEmptyValues('interpretation')"
          >{{ props.row.interpretation }}</b-table-column
        >
        <b-table-column
          v-if="displayClinicalDataCheckboxes"
          field="includeInAuthRequest"
          label="Include in Auth Request"
          width="150px"
          centered
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <b-icon
              title="Please use the checkboxes to unselect any clinical data you do not wish to be included in the authorization request summary"
              icon="circle-info"
              class="includeInAuthRequest"
            />
          </template>
          <template v-slot="props">
            <input
              v-model="props.row.includeInAuthRequest"
              type="checkbox"
              @change="toggleIncludeInAuthRequest($event, props.row.id)"
            />
          </template>
        </b-table-column>
      </ClinicalInputCard>

      <ClinicalInputCard
        title="Medications"
        resource-name="medication"
        :print-mode="printMode"
        :icon-path="require('@assets/images/icons/medications.png')"
        :table-data="medications.data(categorizedClinicalInput)"
        search-column="name"
        :default-sort="['date', 'desc']"
        :opened-detailed="[]"
      >
        <b-table-column
          v-slot="props"
          field="name"
          label="Medication"
          header-class="middleAlign"
          sortable
          >{{ props.row.name }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          field="date"
          label="Date Ordered"
          header-class="middleAlign"
          sortable
        >
          <span :data-date-time="props.row.date">{{
            convertIsoToDateTime(props.row.date)
          }}</span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="status"
          label="Status"
          header-class="middleAlign"
          sortable
          :custom-sort="sortIncludingEmptyValues('status')"
          >{{ props.row.status }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          label="Additional Data"
          header-class="middleAlign"
        >
          <a
            v-if="!printMode && medicationHasAdditionalData(props.row)"
            class="no-select"
            @click="toggleMedicationDetails(props)"
          >
            Details
          </a>
        </b-table-column>

        <b-table-column v-slot="props" label="">
          <a
            v-if="!printMode && props.row.administrations.length > 0"
            class="no-select"
            @click="toggleMedicationAdministrations(props)"
          >
            Administrations
          </a>
        </b-table-column>
        <b-table-column
          v-if="displayClinicalDataCheckboxes"
          field="includeInAuthRequest"
          label="Include in Auth Request"
          width="150px"
          centered
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <b-icon
              title="Please use the checkboxes to unselect any clinical data you do not wish to be included in the authorization request summary"
              icon="circle-info"
              class="includeInAuthRequest"
            />
          </template>
          <template v-slot="props">
            <input
              v-model="props.row.includeInAuthRequest"
              type="checkbox"
              @change="
                toggleIncludeInAuthRequestBulk(
                  $event,
                  props.row.id,
                  props.row.administrations
                )
              "
            />
          </template>
        </b-table-column>
        <template v-slot:detail="props">
          <tr
            v-if="props.row.showInstructions"
            :class="{
              detail: true,
              'custom-selected': props.row.matchingIndicationPaths.length > 0,
              'hide-border': props.row.showAdministrations,
            }"
          >
            <td colspan="6">
              <div class="detail-container detail-row medication-details">
                <ul>
                  <li v-if="props.row.dose">
                    <strong>Dose</strong>: {{ props.row.dose }}
                  </li>
                  <li v-if="props.row.route">
                    <strong>Route</strong>: {{ props.row.route }}
                  </li>
                  <li v-if="props.row.frequency">
                    <strong>Frequency</strong>: {{ props.row.frequency }}
                  </li>
                  <li v-if="props.row.instructions">
                    <strong>Instructions</strong>: {{ props.row.instructions }}
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr
            v-if="props.row.showAdministrations"
            :class="{
              detail: true,
              'custom-selected': props.row.matchingIndicationPaths.length > 0,
            }"
          >
            <td colspan="5">
              <div
                class="detail-container detail-row medication-administrations"
              >
                <span><strong>Administered On</strong>:</span>
                <ul>
                  <li
                    v-for="administration in props.row.administrations"
                    :key="administration.clinicalInputId"
                  >
                    {{ convertIsoToDateTime(administration.dateTime) }}
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </ClinicalInputCard>

      <ClinicalInputCard
        title="Diagnostic Reports"
        resource-name="report"
        :print-mode="printMode"
        :icon-path="require('@assets/images/icons/reports.png')"
        :table-data="diagnosticReports.data(categorizedClinicalInput)"
        search-column="name"
        :default-sort="['date', 'desc']"
        :opened-detailed="printMode ? diagnosticReportsDetailRows : []"
      >
        <b-table-column
          v-slot="props"
          field="name"
          label="Diagnostic Report"
          header-class="middleAlign"
          sortable
          >{{ props.row.name }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          field="date"
          label="Date"
          header-class="middleAlign"
          sortable
          :custom-sort="sortDate((item) => item.date)"
        >
          <span :data-date-time="props.row.date">{{
            convertIsoToDate(props.row.date)
          }}</span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="status"
          label="Status"
          header-class="middleAlign"
          sortable
          :custom-sort="sortIncludingEmptyValues('status')"
          >{{ props.row.status }}</b-table-column
        >

        <b-table-column v-slot="props" label="">
          <div>
            <a
              v-if="
                !printMode &&
                !!props.row &&
                !!props.row.results &&
                props.row.results.length > 0
              "
              class="no-select"
              @click="props.toggleDetails(props.row)"
            >
              Details
            </a>
          </div>
        </b-table-column>
        <b-table-column
          v-if="displayClinicalDataCheckboxes"
          field="includeInAuthRequest"
          label="Include in Auth Request"
          width="150px"
          centered
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <b-icon
              title="Please use the checkboxes to unselect any clinical data you do not wish to be included in the authorization request summary"
              icon="circle-info"
              class="includeInAuthRequest"
            />
          </template>
          <template v-slot="props">
            <input
              v-model="props.row.includeInAuthRequest"
              type="checkbox"
              @change="toggleIncludeInAuthRequest($event, props.row.id)"
            />
          </template>
        </b-table-column>
        <template v-slot:detail="props">
          <tr v-if="props.row.results" class="detail">
            <td colspan="4">
              <div class="detail-container">
                <div
                  v-for="(result, index) in props.row.results"
                  :key="index"
                  class="detail-row"
                >
                  <strong>{{ result.code }}</strong
                  >: {{ result.display }}</div
                >
              </div>
            </td>
          </tr>
        </template>
      </ClinicalInputCard>

      <ClinicalInputCard
        title="Clinical Notes"
        resource-name="clinical note"
        :print-mode="printMode"
        :icon-path="require('@assets/images/icons/clinical-notes.png')"
        :table-data="clinicalNotes.data(categorizedClinicalInput)"
        search-column="name"
        :default-sort="savedClinicalNoteSort"
        :opened-detailed="clinicalNotesDetailRows"
      >
        <b-table-column
          v-slot="props"
          field="name"
          label="Note"
          header-class="middleAlign"
          sortable
          >{{ props.row.name }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="author"
          label="Author"
          header-class="middleAlign"
          sortable
          :custom-sort="sortIncludingEmptyValues('noteAuthor')"
          >{{ props.row.noteAuthor }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          field="date"
          label="Date"
          header-class="middleAlign"
          sortable
        >
          <span :data-date-time="props.row.date">{{
            convertIsoToDateTime(props.row.date)
          }}</span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          label="Note Content"
          header-class="middleAlign"
        >
          <div v-if="props.row.isDownloading"> loading... </div>
          <div v-else>
            <a
              v-if="!printMode"
              :id="props.row.id"
              class="no-select"
              @click="toggleClinicalNotes(props)"
            >
              {{ showClinicalNote(props.row.id) ? 'Close' : 'Open' }}
              <b-icon
                :icon="
                  showClinicalNote(props.row.id) ? 'caret-down' : 'caret-up'
                "
              />
            </a>
          </div>
        </b-table-column>
        <b-table-column
          v-if="displayClinicalDataCheckboxes"
          field="includeInAuthRequest"
          label="Include in Auth Request"
          width="150px"
          centered
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <b-icon
              title="Please use the checkboxes to unselect any clinical data you do not wish to be included in the authorization request summary"
              icon="circle-info"
              class="includeInAuthRequest"
            />
          </template>
          <template v-slot="props">
            <input
              v-model="props.row.includeInAuthRequest"
              type="checkbox"
              @change="toggleIncludeInAuthRequest($event, props.row.id)"
            />
          </template>
        </b-table-column>
        <template v-slot:detail="props">
          <tr class="detail">
            <td colspan="5">
              <div class="detail-container">
                <div
                  :class="
                    props.row.contentType === 'text/html' ? '' : 'detail-row'
                  "
                >
                  <!-- eslint-disable -->
                  <div
                    v-if="
                      props.row.contentType === 'text/html' ||
                      props.row.contentType === 'text/plain'
                    "
                    :class="[
                      'clinical-note-content',
                      props.row.contentType === 'text/plain'
                        ? 'format-white-space'
                        : '',
                    ]"
                    v-html="binaryData(props.row)"
                  />
                  <!-- eslint-enable -->
                  <div
                    v-if="props.row.contentType === 'application/pdf'"
                    class="clinical-note-content"
                  >
                    <keep-alive>
                      <ClinicalNotePdf
                        :content-data="binaryData(props.row)"
                        :doc-id="props.row.id"
                        class="pdf-note-container"
                      />
                    </keep-alive>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </ClinicalInputCard>
    </div>
    <div v-else>
      <b-message
        title="No Clinical Data Found"
        type="is-info"
        :closable="false"
        has-icon
        icon="circle-info"
      >
        Unable to display patient’s clinical data. Please attach any relevant
        clinical data to include as part of this authorization request.
      </b-message>
    </div>
    <AttachmentPanel :print-mode="printMode" />
  </div>
</template>

<style lang="scss">
.clinical-input-container > *:not(:last-child) {
  margin-bottom: 0.75rem;
}

.includeInAuthRequest {
  margin-left: -3px !important;
  color: #025893;
  background-color: white;
}

.middleAlign {
  vertical-align: middle !important;
}

.detail {
  pointer-events: none;
}

.detail-row {
  ul {
    display: flex;
    margin-left: 0;

    li {
      margin: 0;
    }

    li:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.medication-administrations {
  display: flex;

  ul {
    li:not(:last-child) {
      margin-right: 0.5rem;
    }

    li:not(:last-child)::after {
      content: ', ';
    }
  }

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.clinical-input-container .detail-row {
  ul {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    list-style: none;
    list-style-type: none;
  }
}

.b-table .table th {
  padding-right: 30px;
}

.b-table .table tr.detail {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.b-table .table tr.detail .detail-container {
  padding: 0.4rem 1rem;
}

.b-table .table tr.detail .detail-container.medication-details,
.b-table .table tr.detail .detail-container.medication-administrations {
  padding: 0.1rem 1rem;
}

.b-table .table tr.hide-border {
  td {
    border-bottom: 0;
  }
}
.clinical-note-content {
  display: block;
  width: 100%;
  min-height: 75px;
  padding: 5px 5px 5px 20px;
  overflow: hidden;
  overflow-x: auto;
  list-style-position: inside;

  .clinicalNotesPdf {
    position: relative;
    text-align: right;
  }

  &.format-white-space {
    white-space: pre-wrap;
  }

  .pdfWrapper {
    position: relative;
    .pdfContentLayer {
      width: 100%;
    }
    .pdfTextLayer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      line-height: 1;
      opacity: 0.2;
      > span {
        position: absolute;
        color: transparent;
        white-space: pre;
        cursor: text;
        transform-origin: 0% 0%;
      }
    }
  }
}
</style>
