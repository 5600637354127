<template>
  <div :style="{ marginLeft: nodeDepth }">
    <div>
      {{ indicationSelected }}
      <strong v-if="node.matchedIndication">{{ clearText }}</strong>
      <span v-else>{{ clearText }}</span>
    </div>
    <div v-if="hasClinicalInput" class="clinical-data">
      <strong><em>Supporting Clinical Data:</em></strong>
      <div
        v-for="clinicalData in node.clinicalInput"
        :key="clinicalData.clinicalInputId"
      >
        <div>
          <span :class="{ 'border-right': clinicalData.dateTime }">
            {{ clinicalData.name }}
          </span>
          <span
            :class="{ 'border-right': clinicalData.value, 'px-2 py-1': true }"
          >
            {{ formateDate(clinicalData.dateTime) }}
          </span>
          <span class="px-2 py-1">
            {{ clinicalData.value }}{{ clinicalData.unit }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="node.note.length !== 0"
      :style="{
        marginTop: hasClinicalInput ? '5px' : '0',
        marginBottom: '10px',
      }"
    >
      <span class="note">{{ node.note }}</span>
    </div>
  </div>
</template>

<script>
import { formatDateMixin } from '@mixins/format-date-mixin'
export default {
  name: 'TreeDisplay',
  mixins: [formatDateMixin],
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nodeDepth() {
      const path = this.node?.path ?? ''
      return path.split('_').length * 10 + 'px'
    },
    clearText() {
      return this.node.text
        .replace(/\[\[.*?\]\]/g, '') // remove [[...]]
        .replace(/\w+\s+Calculator/gi, '') // remove calculator text
    },
    indicationSelected() {
      return this.node.selected ? '☒' : '☐'
    },
    hasClinicalInput() {
      return this.node?.clinicalInput && this.node.clinicalInput.length > 0
    },
  },
  methods: {
    formateDate(date) {
      return this.convertIsoToDateTime(date)
    },
  },
}
</script>

<style lang="scss" scoped>
.border-right {
  border-right: 1px solid lightgray;
}
.clinical-data,
.note {
  margin-left: 16px;
}
</style>
