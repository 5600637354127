<template>
  <ul>
    <li :id="[id]" class="treeControl">
      <div class="outer-node">
        <div
          :class="[
            'inner-node',
            selected
              ? 'node-selected'
              : highlight
              ? isClinicalMatch
                ? 'highlight'
                : 'highlight-left'
              : '',
            treeRoot ? 'root-node' : '',
          ]"
          @click="toggleSelection"
        >
          <div
            v-if="hasChildren && canToggle"
            :class="[
              'toggle-icon',
              inMatchPath ? 'toggle-circle' : 'toggle-circle-notmatch',
              selected ? 'toggle-selected' : '',
              showChildren ? 'toggle-down' : 'toggle-right',
            ]"
            @click.stop="toggleChildren"
          />
          <div class="node-text">
            <div>
              {{ formatIndicationText(label, calculators) }}
            </div>
            <div
              v-if="citations.length"
              class="node-citations"
              @click.stop="toggleReference('citations')"
            >
              [Refs]
            </div>
            <div
              v-if="annotations.length"
              class="node-annotations"
              @click="toggleReference('annotations', $event)"
            >
              <font-awesome-icon icon="comment-alt" />
            </div>
          </div>
          <div v-if="duplicate" class="node-duplicate" />
          <div
            v-if="isClinicalMatch && hasMatchingClinicalInput"
            :class="['indication-clinical-input', selected ? 'selected' : '']"
          >
            <div
              v-for="(input, i) in filteredClinicalInput"
              :key="input.clinicalInputKey"
              :itemlabel="input.name"
            >
              <div v-if="i < clinicalInputAmount">
                <div>{{ input.name }}</div>
                <div>{{ convertIsoToDateTime(input.dateTime) }}</div>
                <div v-if="filteredClinicalInput.some((i) => i.value)">
                  <div v-if="input.value">
                    {{ input.value }} {{ input.unit }}
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(calc, i) in calculatorMatches" :key="calc.id">
              <div
                v-if="i + filteredClinicalInput.length < clinicalInputAmount"
              >
                <div
                  ><b>Calculator: </b>{{ calc.text }} <b>Score: </b
                  >{{ calc.score }}</div
                >
              </div>
            </div>
          </div>
          <div
            v-if="
              !treeRoot &&
              (filteredClinicalInput.length || indicationNoteEditor)
            "
            :class="['indication-clinical-input', 'colorInvert']"
          >
            <div
              v-if="indicationNoteEditor"
              class="indication-editor-container"
            >
              <textarea
                ref="textarea"
                v-model.trim="treeNodeNote"
                class="indication-editor"
                :class="{ borderoverlimit: overLimit }"
                placeholder="Type note text here..."
                rows="7"
                maxlength="1000"
                @click.stop="setFocus"
              />
              <div class="editor-helper-text" :class="{ overlimit: overLimit }">
                <small>max. characters 1000</small>
                <small class="note-length">{{ treeNodeNote.length }}</small>
              </div>
            </div>
          </div>
          <div v-if="showIndicationNoteIcon && !treeRoot" class="note-icon">
            <img
              :src="
                require(`@assets/images/icons/${
                  noteIconSource || 'addNote.png'
                }`)
              "
              alt="Note Icon Alternate Text"
              @click.stop="toggleIndicationNote"
            />
          </div>
        </div>
      </div>
      <ul v-if="showChildren">
        <TreeNode
          v-for="node in nodes"
          :id="node.PathId"
          :key="node.PathId"
          :path="node.PathId"
          :item-path="node.itemPath"
          :uid="node.uid"
          :definition-id="node.definitionId"
          :nodes="node.ContentItems"
          :current-node="node"
          :label="node.text"
          :match-paths="matchPaths"
          :highlight="node.conditionsMet"
          :node-level="node.nodeLevel"
          :sort-order="node.sortOrder"
          :is-codified="node.isCodified"
          :depth="depth + 1"
          :list-logic="node.ListLogic"
          :citations="node.CitationLinks"
          :annotations="node.AnnotationLinks"
          :duplicate="
            node.indicationsWithConditionsMet
              ? node.indicationsWithConditionsMet.dupIndicationMatch
              : false
          "
          :calculators="node.CalculatorTypes"
          :is-in-definition="node.definitionId || isInDefinition"
          :note-text="getIndicationNote(node.uid)"
          :expanded="hasSelectedChildren"
          @bubbleListLogic="manageListLogic"
        />
      </ul>
    </li>
  </ul>
</template>

<script>
import { formatDateMixin } from '@mixins/format-date-mixin'
import formatIndicationTextMixin from '@mixins/format-indication-text-mixin'

import { mapActions, mapGetters } from 'vuex'
import ListLogicUtils from '@utils/list-logic'

export default {
  name: 'TreeNode',
  mixins: [formatDateMixin, formatIndicationTextMixin],
  // There is no this.ListLogic or this.PathId. These node properties become this.listLogic and this.path.
  props: {
    treeRoot: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: 'id',
    },
    itemPath: {
      type: Array,
      default: () => ['root'],
    },
    uid: {
      type: String,
      default: '',
    },
    definitionId: {
      type: String,
      default: '',
    },
    currentNode: {
      type: Object,
      default: () => ({}),
    },
    nodes: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    matchPaths: {
      type: Object,
      default: () => ({}),
    },
    nodeLevel: {
      type: Number,
      default: 0,
    },
    sortOrder: {
      type: Number,
      default: 0,
    },
    isCodified: {
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
      default: 0,
    },
    listLogic: {
      type: String,
      default: '',
    },
    citations: {
      type: Array,
      default: () => [],
    },
    annotations: {
      type: Array,
      default: () => [],
    },
    duplicate: {
      type: Boolean,
      default: false,
    },
    calculators: {
      type: Array,
      default: () => [],
    },
    isInDefinition: {
      type: String,
      default: '',
    },
    noteText: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: String(Math.random()),
    },
  },
  data() {
    return {
      showChildren: false,
      showMoreClinicalData: true,
      labsAndVitals: { lab: true, vitalsign: true },
      clinicalInputAmount: 5,
      listLogicObject:
        this.depth === 0
          ? ListLogicUtils.parseListLogic(
              this.listLogic?.toLowerCase(),
              this.nodes
            )
          : null,
      indicationNoteEditor: this.noteText,
      treeNodeNote:
        this.noteText ||
        this?.documentedIndicationNotes?.[this.currentNode.uid].note ||
        '',
    }
  },
  computed: {
    ...mapGetters('guidelineData', [
      'clinicalMatches',
      'highlightedIndications',
      'guidelineMetStatuses',
      'circleIndications',
      'clinicalInputs',
      'guideline',
      'notCoveredIndication',
      'hasNotCovered',
      'notCovered',
      'excludeFromAuthRequest',
    ]),
    ...mapGetters('guidelineSearch', ['searchData', 'selectedGuideline']),
    ...mapGetters('documentGuideline', [
      'documentedIndications',
      'documentedIndicationNotes',
    ]),

    root() {
      return this?.guideline?.guideline?.ContentItems
    },
    highlight() {
      return (
        this.currentNode.conditionsMet ||
        this.highlightedIndications?.includes(this.currentNode.PathId)
      )
    },
    isClinicalMatch() {
      return this.clinicalMatches?.includes(this.currentNode.PathId)
    },
    documented() {
      return this.documentedIndications?.find((i) =>
        i.code === this?.guideline?.guidelineInfo?.associatedCode &&
        i.guidelineHsim === this?.guideline?.guidelineInfo?.hsim &&
        Array.isArray(i.node)
          ? i.node.find(
              (n) =>
                n.PathId === this.currentNode.PathId &&
                n.id === this.currentNode.id
            )
          : i.node.PathId === this.currentNode.PathId &&
            i.node.id === this.currentNode.id
      )
    },
    userSelected() {
      return this.documented?.userSelected
    },
    selected() {
      return this.documented != null
    },
    canToggle() {
      return this.nodes
    },
    expandByDefault() {
      const isFirstLevelIndication = this.depth === 1
      if (isFirstLevelIndication) {
        return false
      }
      return this.highlight || this.treeRoot
    },
    hasChildren() {
      return (
        this.nodes[0] &&
        !(
          Object.keys(this.nodes[0]).length === 0 &&
          this.nodes[0].constructor === Object
        )
      )
    },
    inMatchPath() {
      return this.circleIndications?.includes(this.currentNode.PathId)
    },
    calculatorMatches() {
      return []
    },
    clinicalInput() {
      const matchingClinicalInputs =
        this.clinicalInputs?.filter((c) =>
          c.matchingIndicationPaths?.includes(this.currentNode.PathId)
        ) ?? []
      return this.uniquifyClinicalMatches(matchingClinicalInputs)
    },
    filteredClinicalInput() {
      return this.clinicalInput.filter(
        (c) =>
          !this.excludeFromAuthRequest.find(
            (exclude) =>
              exclude.clinicalInputId === c.clinicalInputKey ||
              exclude.clinicalInputId === c.clinicalInputId
          )
      )
    },
    hasMatchingClinicalInput() {
      return this.clinicalInput?.length > 0
    },
    medicationsInput() {
      return []
    },
    noteIconSource() {
      if (this.treeNodeNote.length === 0) {
        if (this.selected) {
          return 'addNoteSelected.png'
        }
        return 'addNote.png'
      }
      if (this.selected) {
        return 'noteAddedSelected.png'
      }
      return 'noteAdded.png'
    },
    showIndicationNoteIcon() {
      return this.eligibleForIndicationNote
    },
    overLimit() {
      return this.treeNodeNote.length > 999
    },

    eligibleForIndicationNote() {
      return !this.isInDefinition && !this.hasNonDefinitionChildren
    },
    hasNonDefinitionChildren() {
      return this.nodes.filter((x) => !x.definitionId).length > 0
    },
    getAssociatedCode() {
      return this?.guideline?.guidelineInfo?.associatedCode !== undefined
        ? this?.guideline?.guidelineInfo?.associatedCode
        : this?.guideline?.guidelineInfo?.hsim
    },
    getIndicationNote() {
      return (uid) => {
        if (
          this.documentedIndicationNotes &&
          this.documentedIndicationNotes[this.getAssociatedCode]
            ?.guidelineHsim === this?.guideline?.guidelineInfo?.hsim
        ) {
          return this.documentedIndicationNotes[
            this.getAssociatedCode
          ]?.nodes.find((n) => n.node.uid === uid)?.note
        }
      }
    },
    hasSelectedChildren() {
      const selectedChildrenNode =
        this.documentedIndications?.find(
          (i) =>
            i.node.PathId.includes(this.currentNode?.PathId) &&
            i.code === this?.guideline?.guidelineInfo?.associatedCode &&
            i.guidelineHsim === this?.guideline?.guidelineInfo?.hsim
        ) ?? []
      if (this.treeRoot && selectedChildrenNode.length > 0) {
        this.setGuidelineMetStatus(this.currentNode)
      }
      return selectedChildrenNode.length > 0
    },
    rootContentItems() {
      return { ContentItems: [this.root, this.notCoveredIndication] }
    },
  },
  watch: {
    treeNodeNote(noteText) {
      this.updateIndicationNote({
        node: this.currentNode,
        note: noteText,
        code: this.getAssociatedCode,
        hsim: this?.guideline?.guidelineInfo?.hsim,
        guidelineTitle: this?.guideline?.guidelineInfo?.guidelineTitle,
        codeDescription: this.getCodeDescription(this.getAssociatedCode),
        sortOrder: this.searchData.map(
          (item) => item.searchOutput.guidelineCode
        ),
        contentEdition: this?.guideline?.guidelineInfo?.contentEdition,
        rootNodes: {
          code: this.getAssociatedCode,
          rootNodes: this.rootContentItems,
        },
      })
    },
  },
  mounted() {
    this.showChildren =
      this.expanded || (this.hasChildren && this.expandByDefault)
    this.openIndicationNote()
  },
  methods: {
    ...mapActions('guidelineData', ['setMetStatus', 'setNotCovered']),
    ...mapActions('documentGuideline', [
      'updateIndicationSelection',
      'toggleIndicationSelection',
      'updateIndicationNote',
      'setOverallStatus',
    ]),
    uniquifyClinicalMatches(clinicalMatches) {
      const uniqueClinicalMatches = clinicalMatches.reduce((acc, input) => {
        // Not using right now but might need it in the future.
        // if (
        //   input.source !== 'patientAgeMeta' &&
        //   input.source !== 'medicationAdministrations'
        // )

        const currentInputKey = `${input.name}${input.dateTime}`
        acc[currentInputKey] = input

        return acc
      }, {})
      return Object.values(uniqueClinicalMatches)
    },
    getMetStatus(node) {
      const metChildren = this.countMetChildren(node)
      const guidelineListLogic = ListLogicUtils.parseListLogic(
        node.ListLogic?.toLowerCase(),
        node.ContentItems
      )

      return ListLogicUtils.isMet(guidelineListLogic, metChildren)
    },
    setGuidelineMetStatus(node) {
      let status = this.getMetStatus(node)
      if (!status) {
        status = this.setUpdatedStatus(node, status)
      }
      this.setOverallStatus({
        status,
        code: this.selectedGuideline?.associatedCode,
        guidelineHsim: this.selectedGuideline?.hsim,
      })
    },

    setUpdatedStatus(node, status) {
      if (this.notCovered) {
        this.rootContentItems.ContentItems.forEach((rootItem) => {
          rootItem.forEach((rootNode) => {
            if (rootNode.PathId !== node.PathId && !status) {
              status = this.getMetStatus(rootNode)
            }
          })
        })
      } else {
        this.getDuplicateNodes(node)?.forEach((rootItem) => {
          rootItem.forEach((rootNode) => {
            if (
              this.guidelineMetStatuses[rootNode.id + '-' + rootNode.PathId] ===
              true
            ) {
              status = true
            }
          })
        })
      }
      return status
    },

    getDuplicateNodes(node) {
      return this.rootContentItems.ContentItems.filter((rootItem) =>
        rootItem.some((rootNode) => rootNode.PathId === node.PathId)
      )
    },

    evaluateListLogic(node) {
      const met = this.getMetStatus(node)
      const sortOrder = this.searchData.map(
        (item) => item.searchOutput.guidelineCode
      )

      if (!this.userSelected) {
        this.updateIndicationSelection({
          node: node,
          newSelectedStatus: met,
          guidelineHsim: this?.guideline?.guidelineInfo?.hsim,
          code: this.getAssociatedCode,
          codeType: this?.guideline?.guidelineInfo?.codeType,
          guidelineTitle: this?.guideline?.guidelineInfo?.guidelineTitle,
          codeDescription: this.getCodeDescription(this.getAssociatedCode),
          sortOrder,
          contentEdition: this?.guideline?.guidelineInfo?.contentEdition,
          rootNodes: {
            code: this.getAssociatedCode,
            rootNodes: this.rootContentItems,
          },
        })

        const id =
          node.id !== undefined ? node.id + '-' + node.PathId : node.PathId
        this.setMetStatus({ action: met, id })
        if (this.treeRoot && this.currentNode.PathId === node.PathId) {
          this.setGuidelineMetStatus(node)
        }
      }

      if (this.hasNotCovered) {
        if (this.notCoveredIndication.length > 0) {
          const selectedChildren = this.notCoveredIndication.filter((child) =>
            child.ContentItems.some(
              (contentItem) =>
                this.guidelineMetStatuses[contentItem.PathId] === true
            )
          )
          const notCoveredStatus = selectedChildren.length > 0
          this.setNotCovered(notCoveredStatus)

          selectedChildren.forEach((selectedNode) => {
            this.updateIndicationSelection({
              node: selectedNode,
              newSelectedStatus: notCoveredStatus,
              guidelineHsim: this?.guideline?.guidelineInfo?.hsim,
              code: this.getAssociatedCode,
              guidelineTitle: this?.guideline?.guidelineInfo?.guidelineTitle,
              codeDescription: this.getCodeDescription(this.getAssociatedCode),
              sortOrder,
              contentEdition: this?.guideline?.guidelineInfo?.contentEdition,
              rootNodes: {
                code: this.getAssociatedCode,
                rootNodes: this.rootContentItems,
              },
            })
            this.setMetStatus({
              action: notCoveredStatus,
              id: selectedNode.PathId,
            })
            if (this.treeRoot && !notCoveredStatus) {
              this.setGuidelineMetStatus(node)
            }
          })
        }
      } else {
        this.setNotCovered(false)
      }
    },
    isNotCovered() {
      if (!this.hasNotCovered) return false
      const selectedChildren = this.notCoveredIndication.filter((child) =>
        child.ContentItems.some(
          (contentItem) =>
            this.guidelineMetStatuses[contentItem.PathId] === true
        )
      )
      return selectedChildren.length > 0
    },
    manageListLogic() {
      if (this.currentNode.ListLogic !== undefined) {
        this.evaluateListLogic(this.currentNode)
        if (!this.treeRoot) {
          this.$emit('bubbleListLogic')
        }
      }
    },
    toggleChildren() {
      this.showChildren = !this.showChildren
    },
    async toggleSelection() {
      if (this.treeRoot) {
        return
      }

      const s = await this.toggleIndicationSelection({
        node: this.currentNode,
        guidelineHsim: this?.guideline?.guidelineInfo?.hsim,
        code: this.getAssociatedCode,
        guidelineTitle: this?.guideline?.guidelineInfo?.guidelineTitle,
        codeDescription: this.getCodeDescription(this.getAssociatedCode),
        sortOrder: this.searchData.map(
          (item) => item.searchOutput.guidelineCode
        ),
        contentEdition: this?.guideline?.guidelineInfo?.contentEdition,
        rootNodes: {
          code: this.getAssociatedCode,
          rootNodes: this.rootContentItems,
        },
        clinicalInput: this.clinicalInput,
      })
      this.setMetStatus({ action: s, id: this.path })
      this.$emit('bubbleListLogic')
    },
    countMetChildren(node) {
      return node.ContentItems?.reduce((metCount, item) => {
        if (this.guidelineMetStatuses[item.PathId] === true) {
          metCount += 1
        }
        return metCount
      }, 0)
    },
    toggleIndicationNote() {
      this.indicationNoteEditor = !this.indicationNoteEditor
      if (this.indicationNoteEditor && !this.selected) this.toggleSelection()
    },
    openIndicationNote() {
      if (
        this.selected &&
        this.showIndicationNoteIcon &&
        this.treeNodeNote.length > 0
      )
        this.indicationNoteEditor = true
    },
    setFocus() {
      this.$nextTick(() => {
        const inputBox = this.$refs.textarea
        if (inputBox) inputBox.focus()
      })
    },
    getCodeDescription(code) {
      const searchDataItem = this.searchData?.find(
        (d) => d?.rulesOutput?.searchTerm === code
      )
      if (searchDataItem !== undefined) {
        return searchDataItem?.searchOutput?.guidelineCodeDescription
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@design';
/* stylelint-disable */
li {
  font-size: 0.8125rem;
  list-style-type: none;
  display: block;
  margin: 0 0 0 20px;
  border-left: solid 1px $light-border-color;
  position: relative;

  &:last-child {
    border-left: none;

    &::before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 25px;
      background: $light-border-color;
    }
  }

  .outer-node {
    padding: 8px 0 0 15px;
    position: relative;

    &::before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 25px;
      width: 15px;
      height: 1px;
      background: $light-border-color;
    }

    .inner-node {
      &:not(.root-node) {
        cursor: pointer;
      }
      padding: 3px;
      border-radius: 3px;
      border: 3px solid transparent;
      position: relative;
      display: flex;
      align-items: center;
      flex-flow: row wrap;

      &::before {
        content: ' ';
        position: absolute;
        left: -3px;
        right: -3px;
        top: -3px;
        bottom: -3px;
        border: dotted 1px $light-border-color;
      }

      &.highlight {
        border: 3px solid $highlight-color;
        &::before {
          border: dotted 1px transparent;
        }
      }

      &.highlight-left {
        border-color: transparent;
        &::before {
          border-style: dotted;
          border-width: 1px 1px 1px 0;
        }
        &::after {
          position: absolute;
          left: -3px;
          top: -3px;
          bottom: -3px;
          width: 3px;
          background: $highlight-color;
          content: ' ';
        }
      }

      &.node-selected {
        background-color: $highlight-color;
        color: $white;

        &::before {
          border: dotted 1px transparent;
        }
      }

      > div {
        &.calculator {
          display: flex;
        }
        &.node-text {
          flex: 1 1 90%;
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          padding: {
            top: 2px;
            left: 2px;
            right: 15px;
            bottom: 2px;
          }
          > div {
            margin-right: 5px;
            position: relative;
          }
        }
        &.node-duplicate {
          height: 20px;
          width: 20px;
          background: $duplicate-selection-color;
          border-radius: 50%;
        }
      }
    }
  }

  .indication-clinical-input {
    border-top: 1px dashed $border-color;
    flex: 1 1 100%;
    padding-top: 3px;
    padding-left: 5px;
    transform: translateY(2px);
    margin: -2px;
    &.selected {
      color: $black;
      background-color: $background-selected-indication-clinical-data-color;
      margin-left: -3px;
      margin-right: -3px;
    }

    > div:not(:last-child) {
      border-bottom: 1px dotted $border-color;
    }

    > div {
      display: flex;

      > div {
        flex: 1 1 100%;
        display: flex;
        flex-flow: row nowrap;
        position: relative;
        padding: 1px;

        > div {
          padding-right: 10px;
          flex: 1;

          &:nth-child(2) {
            padding-left: 10px;
            border-left: solid 1px $border-color;
          }

          &:nth-child(3) {
            flex: 0 1 20%;
            padding-left: 10px;
            border-left: solid 1px $border-color;
          }
        }
      }

      &.toggle-clinical-data {
        color: $duplicate-selection-color;
        position: relative;
        padding-right: 10px;
        justify-content: flex-end;
      }
    }
  }

  .toggle-icon {
    margin-right: 10px;
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;

    &.toggle-circle {
      background: $highlight-color;

      &::before {
        border-color: $white;
      }
    }

    &.toggle-selected {
      background: $white;
      &::before {
        border-color: $highlight-color;
      }
    }

    &.toggle-circle-notmatch {
      border: 1px solid $light-border-color;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 6px;
      height: 6px;
      content: ' ';
      border-color: $black;
      border-style: solid;
      border-width: 2px 2px 0 0;
      transition: 200ms;
    }

    &.toggle-right {
      &::before {
        transform: translate(-65%, -47%) rotate(45deg);
      }
    }
    &.toggle-down {
      &::before {
        transform: translate(-47%, -62%) rotate(135deg);
      }
    }
  }

  .note-icon {
    margin-left: auto;
    position: absolute;
    cursor: pointer;
    top: 6px;
    right: 6px;
  }
  .indication-editor-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 100%;
    padding-right: 5px;
  }

  textarea {
    z-index: 10;
    overflow-y: hidden;
  }

  .editor-helper-text {
    display: flex;
    z-index: 20;
    padding: 5px;
  }

  .indication-editor {
    padding: 3px;
    margin-top: 5px;
    border: 1px solid #00968f;
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    resize: none;
    font-size: 13px;

    &.borderoverlimit {
      border: 1px solid red;
    }
  }
  textarea {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
  }

  .note-length {
    margin-right: 8px;
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    text-align: right;
  }

  .overlimit {
    color: red;
  }

  .indication-clinical-input {
    &:not(.colorInvert) {
      border-top: 1px dashed #dee2e6;
    }
    flex: 1 1 100%;
    padding-top: 3px;
    padding-left: 5px;
    transform: translateY(2px);

    &.colorInvert {
      padding-top: 4px;
      position: relative;
      color: #2c3e50;

      &::before {
        content: ' ';
        position: absolute;
        left: -1px;
        right: -1px;
        top: -1px;
        bottom: -1px;
        background: #e8e8eb;
      }
    }
  }
}

/* stylelint-enable */
</style>
